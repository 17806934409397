import React, {useEffect }  from 'react';
import ReactPlayer from "react-player"
import ScrollFade from "./ScrollFade.js";


import dlOfficeUIPic from "../../assets/images/dlOfficeUIPic.png"
import endeavor from "../../assets/images/endeavor.JPG"
import protDF from "../../assets/images/protDF.png"
import seniorDesign from "../../assets/images/seniorDesign.jpg"
import graphicDesign from "../../assets/images/graphicDesign.png"
import particleFilterImg from "../../assets/images/particleFilter.png";
import ukfIMG from "../../assets/images/ukfIMG.png";


import f1TenthLiDARLogo from "../../assets/images/f1TenthLiDARLogo.png"
import SLAMLogo from "../../assets/images/SLAMLogo.png";
import UKFLogo from "../../assets/images/UKFLogo.png";
import optFlowLogo from "../../assets/images/optFlowLogo.png"
import GaralLogo from "../../assets/images/GaralLogo.png"


import protDFLogo from "../../assets/images/protDamnFineLogo.png"
import virtOfficeLogo from "../../assets/images/virtOfficeLogo.png"
import seniorDesignLogo from "../../assets/images/seniorDesignLogo.png"
import aircraftLogo from "../../assets/images/aircraftLogo.png"
import graphicDesignLogo from "../../assets/images/graphicDesignLogo.png"
import scroll from "../../assets/images/blackScroll.png";

import './workPage.css';



function handleWorkScroll(e) {
  let elem = document.getElementsByClassName("workBody");
  let scrollPercent = (elem[0].scrollTop)/(elem[0].scrollHeight - elem[0].clientHeight);
  let scrollNotif = document.getElementById("workScroll");
  scrollNotif.style.opacity = 1-scrollPercent;
};


function workPopUp(elem) {
  let el = document.getElementById(elem);
  let blocker = document.getElementById("blocker");
  el.style.visibility="visible";
  blocker.style.display="block";
}

function hidePopUp() {
  let blocker = document.getElementById("blocker");
  blocker.style.display="none";
  let els = document.getElementsByClassName("popUp");
  for (let i = 0; i < els.length; i++) { 
    els[i].style.visibility="hidden";
  }
}

function WorkPage() {

  useEffect(() => {
    let elem = document.getElementsByClassName("workBody")
    elem[0].addEventListener("mousewheel", handleWorkScroll);
    elem[0].addEventListener("scroll", handleWorkScroll);
  });

  
  
    return (
      <div>

      <div id = "blocker" onClick= {() => hidePopUp()}/>

      <div className="workBody">
        <div id = "workBodySection">
          <span>
          <h1>MY WORK</h1>
          My work has spanned a range of disciplines: design, robotics, product management, software and mechanical engineering, and writing.
          Take a look below!
          </span>
        </div>
      <div id = "workBodySection2">
      <img src = {scroll} id = "workScroll" width = "auto" height="100px" alt = ""/>

        <div id = "workGrid">

        <div onClick = {() => workPopUp('GaralPopup')} className = "workGridBox">  
            <div className = "workGridImage" style ={{backgroundImage:`url(${GaralLogo})`}}>
              <span>Garal</span>
            </div>
              <div className = "hidden">A multiplayer space combat game with realistic physics.</div>  
        </div>

        <div onClick = {() => workPopUp('2dLiDARPopup')} className = "workGridBox">  
            <div className = "workGridImage" style ={{backgroundImage:`url(${f1TenthLiDARLogo})`}}>
              <span>F1Tenth 2D Lidar Object Tracking</span>
            </div>
              <div className = "hidden">A robust pipeline to identify and track objects on an autonomous car with just 2D LiDAR.</div>  
        </div>


        <div onClick = {() => workPopUp('followtheGapPopUp')} className = "workGridBox">  
          <div className = "workGridImage" style ={{backgroundImage:`url(${f1TenthLiDARLogo})`}}>
            <span>F1Tenth Follow the Gap</span>
          </div>
            <div className = "hidden">Follow the gap obstacle avoidance for autonomous racecar.</div>  
        </div>

        <div onClick = {() => workPopUp('thorSLAMPopUP')} className = "workGridBox">  
          <div className = "workGridImage" style ={{backgroundImage:`url(${SLAMLogo})`}}>
            <span>SLAM</span>
          </div>
            <div className = "hidden">Simultaneous location and mapping with THOR robot with particle filter SLAM.</div>  
        </div>


        <div onClick = {() => workPopUp('UKFPopUp')} className = "workGridBox">  
                <div className = "workGridImage" style ={{backgroundImage:`url(${UKFLogo})`}}>
                  <span>Unscented Kalman Filter</span>
                </div>
                  <div className = "hidden">Filtering drone IMU and accelerometer data to estimate drone orientation with UKF.</div>  
              </div>

          <div onClick = {() => workPopUp('opticalFlowPopUp')} className = "workGridBox">  
                <div className = "workGridImage" style ={{backgroundImage:`url(${optFlowLogo})`}}>
                  <span>Optical Flow</span>
                </div>
                  <div className = "hidden">An optical flow algorithm to track cars moving down a bridge.</div>  
              </div>

          <div onClick = {() => workPopUp('protDFPopUp')} className = "workGridBox">  
            <div className = "workGridImage" style ={{backgroundImage:`url(${protDFLogo})`}}>
               <span>Protocol D-F</span>
            </div>
              <div className = "hidden">A helpful, AI-driven PDF reader.</div>  
          </div>
          <div onClick = {() => workPopUp('virtOfficePopUp')} className = "workGridBox"> 
              <div className = "workGridImage" style ={{backgroundImage:`url(${virtOfficeLogo})`}}>
                <span>Virtual Office</span>
              </div>
              <div className = "hidden">Creating community in a pandemic through code.</div>
          </div>
          <div onClick = {() => workPopUp('seniorDesignPopUp')} className = "workGridBox">  
            <div className = "workGridImage" style ={{backgroundImage:`url(${seniorDesignLogo})`}}>
               <span>Lake Cleaning AUV</span>
            </div>
              <div className = "hidden">A submersible designed to clean up pollutants.</div>  
          </div>
          <div onClick = {() => workPopUp('endeavorPopUp')} className = "workGridBox">
              <div className = "workGridImage" style ={{backgroundImage:`url(${aircraftLogo})`}}>
                <span>Aircraft Design</span>
              </div>
              <div className = "hidden">it flies??</div>
          </div>
          <div onClick = {() => workPopUp('graphicDesignPopUp')} className = "workGridBox">  
            <div className = "workGridImage" style ={{backgroundImage:`url(${graphicDesignLogo})`}}>
               <span>Graphic Design</span>
            </div>
              <div className = "hidden">Magazine design I've done over the years.</div>  
          </div>
          </div>

        </div>
        </div>

        {/* PopUps */}
        <div id = "popUps">

        <div className = "popUp" id ="GaralPopup" style={{visibility:"hidden"}}>
          
          <div className = "popUpWhiteBox">
          <ScrollFade />
            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                SOFTWARE, GAME DEVELOPMENT
              </th>
              <th>
                Garal
              </th>
              <th style={{textAlign: "right"}}>
                FALL 2021
              </th>
              </tr>
              <tr>
              <td colSpan ="3">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=xYJknvXJEBE" 
                controls={false}
                loop = {true}
                playing={true}
                muted={true}
                width="100%"
                playbackRate={1}
              />
              </td>
              </tr>
              <tr>
              <td colSpan ="3">
              After reading John Lumpkin's unfinished <a href = "http://www.thehumanreach.net/" className = "intextLink">Human Reach Series</a>, I found myself
              wanting more: so I built a space warfare game based on the series. The premise: two teams, pink and blue, fighting for 
              control of the glass-planet Garal while bound by the laws of Newtonian physics. 
              
              A few acknowledgements are in order: the sound effects come from Zapsplat, the design of the planet Garal is inspired by 
              <a href = "https://dribbble.com/shots/3221153-Fantastic-Planet-001/" className = "intextLink"> Brenttton on Dribble</a>, and the music is 
              "Quiescent In Time" by <a href = "https://www.silvermansound.com/" className = "intextLink"> Shane Ivers</a>.

              You can enjoy an alpha version of the game <a href = "https://roshanbenefo.com/game" className = "intextLink">here</a>, on my website.
              </td>
              </tr>
            </tbody>
            </table> 
          </div>


        </div>      

        <div className = "popUp" id ="2dLiDARPopup" style={{visibility:"hidden"}}>
          <div className = "popUpWhiteBox">
          <ScrollFade />

            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                SOFTWARE, ROBOTICS
              </th>
              <th>
                F1Tenth 2D Lidar Object Tracking
              </th>
              <th style={{textAlign: "right"}}>
                SPRING 2021
              </th>
              </tr>
              <tr>
              <td colSpan ="3">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=zytNwZZz50Y&feature=youtu.be" 
                controls={false}
                loop = {true}
                playing={true}
                width="100%"
                playbackRate={2}
              />
              </td>
              </tr>
              <tr>
              <td colSpan ="3">
              As part of my independent study with UPenn's mLAB's <a href = "https://f1tenth.org/" className = "intextLink">F1Tenth Team</a>, I built a system
              that can robustly detect and track objects just with the car's onboard 2D LiDAR. The system comprised of a LiDAR clustering algorithm,
              coarse and fine data association, data transformation estimation, and an Extended Kalman Filter. In the above video,
              the pink car on the right is being tracked; we are the purple car. It's represented on the left-hand plot as "T7" (standing for Track #7). The code is available <a href = "https://github.com/mlab-upenn/ISP2021-object_detection/tree/rbenefo_new/wangetall" className = "intextLink">here</a>.
              </td>
              </tr>
            </tbody>
            </table> 
          </div>

        </div>


        <div className = "popUp" id ="followtheGapPopUp" style={{visibility:"hidden"}}>
          <div className = "popUpWhiteBox">
          <ScrollFade />

            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                SOFTWARE, ROBOTICS
              </th>
              <th>
                F1Tenth Follow the Gap
              </th>
              <th style={{textAlign: "right"}}>
                SPRING 2021
              </th>
              </tr>
              <tr>
              <td colSpan ="3">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=iC8HjrGL4UI" 
                controls={false}
                loop = {true}
                playing={true}
                width="100%"
                playbackRate={2}
              />
              </td>
              </tr>
              <tr>
              <td colSpan ="3">
              As part of my independent study with UPenn's mLAB's <a href = "https://f1tenth.org/" className = "intextLink">F1Tenth Team</a>, I created
              a system in ROS to allow an autonomous racecar to avoid obstacles while navigating a complex environment. The system was based on the 
              Follow the Gap method (FGM). The code is available <a href = "https://github.com/rbenefoz/F1Tenth/tree/main/reactive_methods" className = "intextLink">here</a>!
              </td>
              </tr>
            </tbody>
            </table> 
          </div>
          {/* <div className = "blocker" onClick= {() => hidePopUp("followtheGapPopUp")}/> */}
        </div>
        
        <div className = "popUp" id ="thorSLAMPopUP" style={{visibility:"hidden"}}>
          <div className = "popUpWhiteBox">
          <ScrollFade />

            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                SOFTWARE, ROBOTICS
              </th>
              <th>
                Particle Filter SLAM
              </th>
              <th style={{textAlign: "right"}}>
                SPRING 2021
              </th>
              </tr>
              <tr>
              <td colSpan ="3">
              <center>
              <img src = {particleFilterImg} width="80%" height="auto" alt = ""/> </center></td>
              </tr>
              <tr>
              <td colSpan ="3">
              I designed a particle-filter-based simultaneous location and mapping (SLAM) algorithm for the <a href = "https://penntoday.upenn.edu/2015-06-11/latest-news/penn-roboticists-test-their-mettle-darpa-challenge" className = "intextLink">THOR robot</a> using
              its 2D LiDAR and odometry estimates. In the above image, you can see the output of the algorithm: the map is drawn in white and black,
              and the trajectory of the robot is drawn in red. The trajectory given by the raw odometry measurements are in blue– as can be seen, they
              have a significant amount of drift.
              </td>
              </tr>
            </tbody>
            </table> 
          </div>
        </div>

        <div className = "popUp" id ="UKFPopUp" style={{visibility:"hidden"}}>
          <div className = "popUpWhiteBox">
          <ScrollFade />

            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                SOFTWARE, ROBOTICS
              </th>
              <th>
                Quadrotor Orientation UKF
              </th>
              <th style={{textAlign: "right"}}>
                SPRING 2021
              </th>
              </tr>
              <tr>
              <td colSpan ="3">
                <center>
              <img src = {ukfIMG} width="60%" height="auto" alt = ""/> </center></td>
              
              </tr>
              <tr>
              <td colSpan ="3">
              I built an Unscented Kalman Filter (UKF) to fuse data from a quadrotor's gyroscope and accelerometer to
              accurate estimate its orientation. UKFs are generally used for sensor fusion when the data coming into them
              is highly nonlinear. In the above image, you can see the output of the UKF plotted against data from
              the external VICON motion tracking system, which was treated as the ground truth data.
              </td>
              </tr>
            </tbody>
            </table> 
          </div>
        </div>


        <div className = "popUp" id ="opticalFlowPopUp" style={{visibility:"hidden"}}>
          <div className = "popUpWhiteBox">
          <ScrollFade />

            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                SOFTWARE, ROBOTICS
              </th>
              <th>
                Optical Flow Car Tracking
              </th>
              <th style={{textAlign: "right"}}>
                FALL 2020
              </th>
              </tr>
              <tr>
              <td colSpan ="3">
                <center>
                <ReactPlayer
                url="https://www.youtube.com/watch?v=XiYYJkabCuw" 
                controls={false}
                loop = {true}
                playing={true}
                width="100%"
                playbackRate={1}
              />
              </center></td>
              
              </tr>
              <tr>
              <td colSpan ="3">
              I designed and built a robust optical flow algorithm to track objects in a video. Optical flow estimates the motion of an object in a series of images
              by taking the gradient of subsequent images, and "forward-projecting" pixels by that gradient. In the above video,
              car positions are estimated via the red bounding boxes, and the tracked pixels are green. While deep learning algorithms
              are generally more accurate for applications like this, my implementation demonstrates the fact that
              even simple algorithms can still yield great results.
              </td>
              </tr>
            </tbody>
            </table> 
          </div>
        </div>


        <div className = "popUp" id ="virtOfficePopUp" style={{visibility:"hidden"}}>
          <div className = "popUpWhiteBox">
          <ScrollFade />

            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                SOFTWARE, DESIGN, PM
              </th>
              <th>
                VIRTUAL OFFICE
              </th>
              <th style={{textAlign: "right"}}>
                SUMMER 2020
              </th>
              </tr>
              <tr>
              <td colSpan ="3">
              <img src = {dlOfficeUIPic} width="100%" height="auto" alt = ""/>
              </td>
              </tr>
              <tr>
              <td colSpan ="3">
              As an intern at Deeplocal, I worked with two designers to create the company's Virtual Office. We designed 
              the webapp to also help company employees feel connected to the company while working from home during the COVID pandemic–
              the building reacts to data inputs from the new platform for office banter: Slack. We also 
              hid a number of easter eggs that reveal information about the company's culture and history for company outsiders.
              I worked on the full stack: the Express server, React frontend, and deployment.
              </td>
              </tr>
            </tbody>
            </table> 
          </div>
        </div>
        <div className = "popUp" id ="endeavorPopUp" style={{visibility:"hidden"}}>
          <div className = "popUpWhiteBox">
          <ScrollFade />

            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                MECHANICAL
              </th>
              <th>
                ENDEAVOR
              </th>
              <th style={{textAlign: "right"}}>
                SPRING 2019
              </th>
              </tr>
              <tr>
              <td colSpan ="3">
              <img src = {endeavor} width="100%" height="auto" alt = ""/>
              </td>
              </tr>
              <tr>
              <td colSpan ="3">
              I spent three semesters at Penn leading the aircraft team of the Penn Aerospace Club. While 
              lead, I oversaw the design and construction of three UAVs: Herbat, Endeavor, and Boing. Pictured above is
              Endeavor, a lightweight carbon fiber and balsa wood pusher UAV, designed to have a cruising speed of 20 mph and weigh 
              around 2 pounds.
              </td>
              </tr>
            </tbody>
            </table> 
          </div>
        </div>

        <div className = "popUp" id ="protDFPopUp" style={{visibility:"hidden"}}>
          <div className = "popUpWhiteBox">
          <ScrollFade />

            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                SOFTWARE, DESIGN, PM
              </th>
              <th>
                Protocol D-F
              </th>
              <th style={{textAlign: "right"}}>
                SUMMER 2020
              </th>
              </tr>
              <tr>
              <td colSpan ="3" className = "popUpImage" style ={{backgroundImage:`url(${protDF})`, backgroundPosition: "50% 0%"}}>
              </td>
              </tr>
              <tr>
              <td colSpan ="3">
              I spent the first half of the summer of 2020 learning how to do web development with my friend Wes.
              Neither of us had much experience with software or web development, but we were keen to learn.
              We built a PDF reader from the ground up, developing native PDF editing and visualization
              packages to give us full control over PDF rendering. We also included a number of smart features:
              smart search, and sentence similarity, to upgrade the PDF viewing experience. Over the summer,
              I worked on the full stack: the UX design, React frontend, Express backend, Mongo database connectivity, and 
              smart search features.
              </td>
              </tr>
            </tbody>
            </table> 
          </div>
        </div>
       
        <div className = "popUp" id ="seniorDesignPopUp" style={{visibility:"hidden"}}>
          <div className = "popUpWhiteBox">
          <ScrollFade />

            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                SOFTWARE, MECHANICAL, PM
              </th>
              <th>
                FREE WILLY: LAKE CLEANUP AUV
              </th>
              <th style={{textAlign: "right"}}>
                FALL 2019, SPRING 2020
              </th>
              </tr>
              <tr>
              <td colSpan ="3">
              <img src = {seniorDesign} width="100%" height="auto" alt = ""/>
              </td>
              </tr>
              <tr>
              <td colSpan ="3">
              For our Senior Design project, my friends and I created an underwater pollution collection platform we
              called Free Willy. The platform was designed to be as modular as possible to increase its use cases, with 
              collection filters that could be easily swapped out to collect different kinds of pollutants. I worked on
              the software systems of the robot, designing its communication packages, a tracking dashboard via AWS,
              a path planner, and control package. I also helped conceptualize and pitch the business case for our drone.
              </td>
              </tr>
            </tbody>
            </table> 
          </div>
        </div>

        <div className = "popUp" id ="graphicDesignPopUp" style={{visibility:"hidden"}}>
          <div className = "popUpWhiteBox">
          <ScrollFade />

            <table className = "popUpTable">
              <tbody>
              <tr>
              <th style={{textAlign: "left"}}>
                DESIGN
              </th>
              <th>
                GRAPHIC DESIGN
              </th>
              <th style={{textAlign: "right"}}>
                2018, 2019
              </th>
              </tr>
              <tr>
              <td colSpan ="3">
              <img src = {graphicDesign} width="100%" height="auto" alt = ""/>
              </td>
              </tr>
              <tr>
              <td colSpan ="3">
              I ran the design team of the Penn Science Magazine while at Penn, where I taught my team of 
              designers how to create compelling and beautiful graphics to accompany the student research
              and articles in the magazine. I personally created the covers and tables of contents for the magazine's Fall
              2018 and Spring 2019 issues, pictured above.
              </td>
              </tr>
            </tbody>
            </table> 
          </div>
        </div>

    </div>

    </div>
    )
};



export default WorkPage;
