import React from 'react';
import './AboutBody.css';
import aboutBackground from "../../assets/images/aboutBackground.png";
import spacecraft from "../../assets/images/spacecraft.png";

import reentryBurn from "../../assets/images/reentryBurn.png";
import scroll from "../../assets/images/scroll.png";

export default class AboutBody extends React.Component {

    constructor(props) {
      super(props);
      this.state = {  
      }  
    }
    componentDidMount() {
      window.addEventListener("mousewheel", this.handleScroll);
      window.addEventListener("scroll", this.handleScroll);

    }
    componentWillUnmount() {
      window.removeEventListener("mousewheel", this.handleScroll);
      window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll() {
      let title = document.getElementById("aboutHello");
      title.classList.add("upNAway")
      let background = document.getElementById("aboutBackground");
      background.classList.add("landing")
      let spacecraft = document.getElementById("spacecraft");
      spacecraft.classList.add("spacecraftLanding")
      let reentryBurn = document.getElementById("reentryBurn");
      reentryBurn.classList.add("burnOff")
      let aboutText = document.getElementById("aboutText");
      aboutText.classList.add("aboutTextShow")
      let scroll = document.getElementById("scroll");
      scroll.classList.add("vanish")

    }
  
  

    render() {
      return (
        <div id = "aboutBody">
        <div id = "aboutBodySection1">
          <div id = "aboutHello">
          <span>HI,</span> <br/><span>I'M ROSHAN.</span>
          </div>
          <img src = {aboutBackground} id = "aboutBackground" alt = ""/>
          <img src = {reentryBurn} id = "reentryBurn" width = "auto" height="200px" alt = ""/>

          <img src = {spacecraft} id = "spacecraft" width = "auto" height="100px" alt = ""/>
          <img src = {scroll} id = "scroll" width = "auto" height="100px" alt = ""/>

          <div id = "aboutText">
          I graduated from Penn in 2021, where I did a masters in robotics engineering, along with Penn's M&amp;T program. 
          As you can probably tell, I'm into all things space-related. I'm also interested in autonomous vehicles, 
          and in the long run, hope to combine my experience in business and
          engineering by starting an aerospace or AV company.

          Also high up on my list are design, philosophy, cycling, and fruit salad.
          </div>
      </div>

      </div>
      );
    }
  
  }
