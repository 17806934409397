//////

//modified from https://github.com/benestudio/react-scrollfade/blob/master/lib/ScrollFade.tsx

//////

import React, { useCallback, useEffect, useRef } from 'react';

const easeIn = (t, alpha) => Math.pow(t, alpha);


const ScrollFade = () => {
    const rootRef = useRef(null);

    const onScroll = useCallback(() => {
        const scrollElement = rootRef.current?.parentElement;
        if (scrollElement) {
            const { offsetHeight: elementHeight, scrollTop } = scrollElement;
            const opacity = easeIn((elementHeight-scrollTop) / (elementHeight), 10);

            scrollElement.style.boxShadow = "inset 0 -20px 15px -10px rgba(184, 184, 184,"+opacity+"), 0px 6px 26px -17px #4d4d4d";
        }
    }, []);

    useEffect(() => {
        const scrollElement = rootRef.current?.parentElement;

        if (scrollElement) {
            const { offsetHeight, scrollHeight } = scrollElement;
            if (offsetHeight !== scrollHeight) {
                scrollElement.style.boxShadow = "inset 0 -20px 15px -10px rgba(184, 184, 184,"+0.9+"), 0px 6px 26px -17px #4d4d4d";
            }
            scrollElement.addEventListener('scroll', onScroll);
            return () => scrollElement.removeEventListener('scroll', onScroll);
        }
    }, [onScroll]);

    return <div className="scroll-fade" ref={rootRef} />;
};

export default ScrollFade;