import React, { useState, useEffect } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import './GamePage.css';

const unityContext = new UnityContext({
    loaderUrl: "/build/GaralHTML.loader.js",
    dataUrl: "/build/GaralHTML.data",
    frameworkUrl: "/build/GaralHTML.framework.js",
    codeUrl: "/build/GaralHTML.wasm",
  });
  

// var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;

function GamePage() {
  var width = window.innerWidth;
  var height = window.innerHeight;

  const [progression, setProgression] = useState(0);

  useEffect(function () {
    unityContext.on("progress", function (progression) {
      setProgression(progression);
      if (progression  === 1){
        let loading = document.getElementById("loading");
        loading.classList.add("gone");
      };

    });
  }, []);


  useEffect(function () {
    unityContext.on("canvas", function (canvas) {
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;
    });
  }, []);
  if (width <= 600 || height <= 500){
    return (
      <div id = "tooSmall"><span id = "tooSmallText">Unfortunately, your device screen is too small to play Garal.
      <br/><br/>Please try Garal once you've found a larger device!</span></div>
    );
  }

  return (
    <div id = "gameBody">
    <div id = "game">
    <Unity
      unityContext={unityContext}
      style={{ width: "100%", height: "100%" }}
      devicePixelRatio={2}
    />
    </div>
    <div id = "loading">
      <div id = "progressBar">
    
  <CircularProgressbar
  value={progression * 100}
  strokeWidth ={3}
  styles={buildStyles({
    // Rotation of path and trail, in number of turns (0-1)

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: 'butt',

    // Text size
    textSize: '16px',

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,

    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',
    transformOrigin: 'center center',


    // Colors
    pathColor: `rgba(255, 255, 255)`,
    textColor: '#f88',
    trailColor: 'rgba(0, 0, 0)',
    backgroundColor: 'rgba(0, 0, 0)',
  })}
/>
</div>
    </div>

    </div>
  );
}

  
export default GamePage;


